import styled from '@emotion/styled'
import { TechnicalError } from '@orus.eu/error'
import {
  ContentContainerAppClient,
  NameAuthenticationDialog,
  PageTitle,
  PersistentNotification,
  useTranslate,
} from '@orus.eu/pharaoh'
import { useLocation } from '@tanstack/react-router'
import { useState } from 'react'
import { trpcReact } from '../../client'
import { GlobalLoadingState } from '../molecules/global-loading-state'
import { UpdatePaymentForm } from '../organisms/payment-form/update-payment-form'

export default function PaymentMethodUpdatePageWithToken(): JSX.Element {
  const translate = useTranslate()
  const search = useLocation().search

  const token = search.token

  const nameLengthQuery = trpcReact.paymentMethod.getCustomerNameLengthWithoutConnection.useQuery(
    {
      jwt: token || '',
    },
    { enabled: !!token },
  )

  const nameLength = nameLengthQuery.data?.type === 'success' ? nameLengthQuery.data.output : undefined

  const [nameFirstLettersUserInput, setNameFirstLettersUserInput] = useState('')

  const authenticationResult = trpcReact.paymentMethod.checkSessionLessAuthentication.useQuery(
    {
      token: token || '',
      nameFirstLettersUserInput,
    },
    { enabled: !!token && !!nameFirstLettersUserInput },
  )

  const success = search.redirect_status
  if (success === 'succeeded') {
    return (
      <PersistentNotification variant="success">{translate('update_payment_method_succeeded')}</PersistentNotification>
    )
  }

  if (nameLengthQuery.isLoading) {
    return <GlobalLoadingState />
  }

  if (authenticationResult.data?.type !== 'success') {
    return <NameAuthenticationDialog nameLength={nameLength} tryAuthentication={setNameFirstLettersUserInput} />
  }

  if (!token) {
    throw new TechnicalError('Token is missing')
  }

  return (
    <ContainerWithOverflowScroll>
      <PageTitle title={translate('subscription_funnel_checkout_payment_details')} />
      <ContentContainerAppClient>
        <UpdatePaymentForm backButton={false} sessionLessAuth={{ token, lastNameLetters: nameFirstLettersUserInput }} />
      </ContentContainerAppClient>
    </ContainerWithOverflowScroll>
  )
}

const ContainerWithOverflowScroll = styled.div`
  max-height: 100vh;
  overflow: scroll;
`
